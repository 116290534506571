import React, {Component} from "react";
import {BMServerTraffic} from "../model/BMServerTraffic";
import {BMServer} from "../model/BMServer";
import {EuiFlexGroup, EuiFlexItem} from "@elastic/eui";

interface TrafficProps{
    onTrafficLow: (warning:string)=>void
    traffic : BMServerTraffic
    setTraffic:React.Dispatch<React.SetStateAction<BMServerTraffic>>
    servers: Map<String,BMServer>
    setServers:React.Dispatch<React.SetStateAction<Map<String,BMServer>>>
}

export class NetworkTrafficUpdateClass extends Component<TrafficProps, any> {
    private intervalTimer: NodeJS.Timer | undefined;

    constructor({props}: { props: any }) {
        super(props);
        this.state = {
            traffic: " - ",
            expected: " - "
        };
    }
    componentDidMount() {
        this.loadNetWorkSpeed()
    }
    loadNetWorkSpeed() {
        fetch("/api-currentExpectedTraffic?isApi=true")
            .then(res => res.json())
            .then(
                (result) => {
                    let resultObj:BMServerTraffic = {
                        currentTraffic: Number(result.currentTraffic),
                        splTraffic: Number(result.splTraffic),
                        karakTraffic: Number(result.karakTraffic),
                        cutOff: Number(result.cutOff),
                        minTrafficReq: Number(result.minTrafficReq),
                        pastTraffic: Number(result.pastTraffic),
                        servers: new Map(Object.entries(result.servers)),
                        traffic14DaysAgo: Number(result.traffic14DaysAgo),
                        traffic7DaysAgo: Number(result.traffic7DaysAgo),
                        trafficYesterday : Number(result.trafficYesterday),
                        traffic7DaysAgoSpl : Number(result.traffic7DaysAgoSpl),
                        traffic7DaysAgoKarak : Number(result.traffic7DaysAgoKarak),
                        traffic14DaysAgoSpl : Number(result.traffic14DaysAgoSpl),
                        traffic14DaysAgoKarak : Number(result.traffic14DaysAgoKarak),
                        trafficYesterdaySpl : Number(result.trafficYesterdaySpl),
                        trafficYesterdayKarak : Number(result.trafficYesterdayKarak)
                    }
                    console.log(resultObj)
                    this.props.setTraffic(resultObj)
                },
                (error) => {
                    console.log(error)
                }
            ).catch(e=>{
                console.log(e)
            }).finally(()=>{
                if(this.intervalTimer){
                    clearInterval(this.intervalTimer)
                }
                this.intervalTimer = setInterval(() => {
                    this.loadNetWorkSpeed()
                }, 60000);
            })
    }
    formatNumber(num:string){
        return Number(num).toFixed(2)
    }
    render() {
        return <div>
            <EuiFlexGroup>
                <EuiFlexItem>
                    <h1 style={{textAlign:"right" , fontWeight:"bold"}}>Total</h1>
                    <div style={{textAlign:"right"}}>
                        <small>{ (this.props.traffic.currentTraffic/1024).toFixed(2) } MBps (Now)</small> <br />
                        <small>{ (this.props.traffic.minTrafficReq/1024).toFixed(2) } MBps (Min)</small> <br />
                        <small>{ (this.props.traffic.trafficYesterday/1024).toFixed(2) } MBps (yesterday)</small> <br />
                        <small>{ (this.props.traffic.traffic7DaysAgo/1024).toFixed(2) } MBps (7 days ago)</small> <br />
                        <small>{ (this.props.traffic.traffic14DaysAgo/1024).toFixed(2) } MBps (14 days ago)</small>
                    </div>
                </EuiFlexItem>
                <EuiFlexItem>
                    <h1 style={{textAlign:"right" , fontWeight:"bold"}}>Spl</h1>
                    <div style={{textAlign:"right"}}>
                        <small>{ (this.props.traffic.splTraffic/1024).toFixed(2) } MBps (Now)</small> <br />
                        <small>{ (this.props.traffic.trafficYesterdaySpl/1024).toFixed(2) } MBps (Yesterday)</small> <br />
                        <small>{ (this.props.traffic.traffic7DaysAgoSpl/1024).toFixed(2) } MBps (7 days ago)</small> <br />
                        <small>{ (this.props.traffic.traffic14DaysAgoSpl/1024).toFixed(2) } MBps (14 days ago)</small> <br />
                    </div>
                </EuiFlexItem>
                <EuiFlexItem>
                    <h1 style={{textAlign:"right" , fontWeight:"bold"}}>Karak</h1>
                    <div style={{textAlign:"right"}}>
                        <small>{ (this.props.traffic.karakTraffic/1024).toFixed(2) } MBps (Now)</small> <br />
                    <small>{ (this.props.traffic.trafficYesterdayKarak/1024).toFixed(2) } MBps (Yesterday)</small> <br />
                    <small>{ (this.props.traffic.traffic7DaysAgoKarak/1024).toFixed(2) } MBps (7 days ago)</small> <br />
                    <small>{ (this.props.traffic.traffic14DaysAgoKarak/1024).toFixed(2) } MBps (14 days ago)</small> <br />
                    </div>

                </EuiFlexItem>


            </EuiFlexGroup>
        </div>

            ;
    }
}
