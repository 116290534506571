import React, {Component, FunctionComponent, useEffect, useState} from "react";
import {collection, getDocs, doc , query, where,QuerySnapshot} from "firebase/firestore";
import {bmServerCollectionName, db} from "../application/FirebaseConfigs";
import {EuiFlexItem,EuiButton,EuiFlexGroup} from "@elastic/eui";
import {ServerTable} from "./ServerTable";
import {BMServer} from "../model/BMServer";
import {UpdateServerModal} from "./UpdateServerModal";
import {BMServerTraffic} from "../model/BMServerTraffic";
interface IProps {
    traffic : BMServerTraffic
    servers: Map<String,BMServer>
    setServers:React.Dispatch<React.SetStateAction<Map<String,BMServer>>>
}

export const ServerSection:FunctionComponent<IProps> = (props)=>{
    const [serverSnapShot,setServerSnapshot] = useState<QuerySnapshot>();

    useEffect(()=>{
        const q = query(collection(db, ""+bmServerCollectionName+""), where("is_active", "==", true));
        getDocs(q).then((querySnapshot)=>{
            setServerSnapshot(querySnapshot)
        }).finally(()=>{

        })
    },[])
    const showData=()=>{
        if(serverSnapShot){
            return (<>
                    <ServerTable setServers={props.setServers} servers={props.servers} snapshot={serverSnapShot}  traffic={props.traffic}/>
                    <div style={{width:"100%",marginTop:"20px"}}>
                        <EuiFlexItem grow={true} style={{float:"left"}}>

                        </EuiFlexItem>
                        <EuiFlexItem grow={true} style={{float:"right"}}>
                            <UpdateServerModal showModal={false} onServerChange={s => {}} servers={props.servers} setServers={props.setServers} traffic={props.traffic}/>
                        </EuiFlexItem>
                    </div>
                </> )
        }else{
            return (<div>Loading</div>)
        }
    }
    return (
        <div> {showData()} </div>
    );
}
