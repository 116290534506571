import { initializeApp } from "firebase/app";
import {getAuth, GoogleAuthProvider} from "firebase/auth";
import {collection, doc, getDocs, getFirestore, query, where, writeBatch,onSnapshot} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import {BMServer, BMServerToUpdate, IP, IP_STATUS} from "../model/BMServer";


let isInitialisedFireStore = false;

const firebaseConfig = {
    apiKey: "AIzaSyCJVjr-_Y-v8eJcBFuP6dUCJTtSnfGTvqE",
    authDomain: "com-cool-vpn.firebaseapp.com",
    databaseURL: "https://com-cool-vpn-default-rtdb.firebaseio.com",
    projectId: "com-cool-vpn",
    storageBucket: "com-cool-vpn.appspot.com",
    messagingSenderId: "889974893661",
    appId: "1:889974893661:web:137ac3b26a4ef0a5b5b1b4",
    measurementId: "G-RCKTFT2H9G"
};
export const isDevMode = ( window.location.hostname == 'localhost' || window.location.hostname  == 'ryn-servers.wcheck.in' );

export const bmServerCollectionName = isDevMode ? "bm_servers_dev" : "bm_servers"

export const app = initializeApp(firebaseConfig);


export const authentication = getAuth(app);

export const db = getFirestore()

export const provider = new GoogleAuthProvider();

export const functions = getFunctions(app,window.location.origin)

export const updateServerApi = httpsCallable(functions, 'api-updateServerApi');

export function initFireStoreEvents(listenerFn: ()=>void){
    if(isInitialisedFireStore) return;
    isInitialisedFireStore = true;
    const q = query(collection(db, ""+bmServerCollectionName+""));
    getDocs(q).then(snapshot => {
        for (const currentDoc of snapshot.docs) {
            onSnapshot(doc(db, bmServerCollectionName,currentDoc.id), { includeMetadataChanges: true },(updatedSnapshot) => {
                console.log("Fire Store data update ")
                listenerFn()
            })
        }
    });
}

export async function updateIpsForBMServer(server: BMServer ) {
    let batch = writeBatch(db)
    server.ips.forEach(ip=>{
        const ipRef = doc(db, ""+bmServerCollectionName+"",  server.serverName+"/ips/"+ip.ip);
        batch.set(ipRef, {status: ip.status},{merge: true});
    })
    await batch.commit()
    return true
}
export function fetchServerInfo(onSuccess: (res: Map<string,BMServer>) => void, onFail: () => void) {
    let servers = new Map<string,BMServer>();
    const q = query(collection(db, ""+bmServerCollectionName+""), where("is_active", "==", true));
    getDocs(q).then(snapshot => {
        let count = snapshot.size;
        let found = 0
        for (const doc of snapshot.docs) {
            fetchIpInfo({
                currentIp: doc.get("current_ip"),
                ips: [],
                link: doc.get("current_ip"),
                serverName: doc.id,
                traffic: 0,
                hasBadTraffic: false,
                updatedBy: doc.get("updatedBy"),
                updatedOn : doc.get("updatedOn")
            }).then( server => {
                servers.set(doc.id, server)
            }).finally(()=>{
                found++;
                if(count==found){
                    onSuccess(servers)
                }
            })
        }
        return servers
    }).catch(e=>{
        onFail()
    });
}
export async function fetchIpInfo(server:BMServer) {
    server.ips = []
    let q2 = query(collection(db, ""+bmServerCollectionName+"/"+server.serverName+"/ips"), where("status", "!=", IP_STATUS.DISABLED));
    let snapshot2 = await getDocs(q2)
    for(const  doc2 of snapshot2.docs){
        server.ips.push({ip: doc2.id, status: doc2.get("status")})
    }
    return server
}
export async function updateBMServer(servers:BMServerToUpdate[]) {
    let batch = writeBatch(db)
    let newIps:string[] = []
    servers.forEach(n=>{
        let ipRef = doc(db, ""+bmServerCollectionName+"", n.serverName);
        let bmServerUpdate = JSON.parse("{}");
        bmServerUpdate.current_ip = n.newIp;
        if(n.currentIp!=n.newIp){
            bmServerUpdate.updatedBy = authentication.currentUser?.displayName
            bmServerUpdate.updatedOn = new Date().getTime()
        }
        batch.set(ipRef, bmServerUpdate, {merge: true});
        if(n.currentIp!=n.newIp&&n.currentIp!="") {
            let ipRef2 = doc(db, ""+bmServerCollectionName+"", n.serverName+"/ips/"+n.currentIp);
            let ip_update = JSON.parse("{}")
            ip_update["note"] = n.reason.toString()
            if(n.reason==IP_STATUS.BLOCKED) {
                ip_update["status"] = IP_STATUS.BLOCKED.toString()
            }
            batch.set(ipRef2, ip_update, {merge: true});
        }
        if(n.newIp!=""){
            newIps.push(n.newIp.trim().replace(":443","")+":443")
        }
    })
    await batch.commit()
    return (newIps.join(","))
}