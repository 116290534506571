import React, {ChangeEvent, FunctionComponent, useState} from "react";
import {BMServer, IP, IP_STATUS} from "../model/BMServer";
import {
    EuiButton,
    EuiFlexGroup,
    EuiFlexItem,
    EuiModal,
    EuiModalBody,
    EuiModalFooter,
    EuiModalHeader,
    EuiModalHeaderTitle,
    EuiSelect
} from '@elastic/eui';
import {doc, setDoc , serverTimestamp } from "firebase/firestore";
import {bmServerCollectionName, db, fetchIpInfo, updateIpsForBMServer} from "../application/FirebaseConfigs";
import {trafficURL} from "../model/BMServerTraffic";
import {DnsLookup} from "./DnsLookup";

interface IProps {
    server: BMServer|undefined
    showConfigPage:boolean
    setShowConfigPage:React.Dispatch<React.SetStateAction<boolean>>
    onServerChange:(server:BMServer)=>void

}
interface IPStateController{
    status:IP_STATUS;
    setStatus:React.Dispatch<React.SetStateAction<IP_STATUS>>
}
export const BMConfiguration: FunctionComponent<IProps> = (props) => {
    const STATUS=[
        {"value":"ACTIVE","text":"ACTIVE"},
        {"value":"NEW","text":"NEW"},
        {"value":"BLOCKED","text":"BLOCKED"},
        {"value":"DISABLED","text":"DISABLED"}
    ]
    const [serverCurrentIp,setServerCurrentIp] = useState(props.server?.currentIp);

    function addNewIp() {
        let newIp = prompt("Please enter Newe Ip", "");
        if(newIp!=null && newIp.trim().length>0) {
            processIps(newIp)
                .then(r => { })
                .finally(()=>{
                    if(props.server) {
                        fetchIpInfo(props.server)
                            .then(res => {
                                props.onServerChange(res)
                            })
                    }
            })
        }
    }
    async function processIps(ips:string){
        let ipArray = ips.split(",")
        for( let ip of ipArray ){
            await validateAndAddNewIp(ip.trim().replace(":443", ""))
        }
    }
    async function validateAndAddNewIp(ip:string){
        let responseObj = await fetch(trafficURL(ip))
        let response = await responseObj.json()
        let traffic = (response["max"]/1024)
        await setDoc(doc(db, ""+bmServerCollectionName+"/" + props.server?.serverName + "/ips", ip), {
                note: "",
                created_date: serverTimestamp(),
                status: IP_STATUS.NEW
            }, {merge: true})
        return {ip: ip, status: IP_STATUS.NEW}
    }

    function saveServerData() {
        if(props.server) {
            updateIpsForBMServer(props.server)
                .then((res)=>{
                    if(res){
                        props.setShowConfigPage(false)
                    }
                })
                .catch(e => {
                    console.log(e)
                })
        } else {
            alert("Failed to save server details")
        }
    }
    function renderModel() {
        if (!props.showConfigPage || !props.server) {
            return <div/>;
        }
        return (<div>
            <EuiModal onClose={() => props.setShowConfigPage(false)}>
                <EuiModalHeader>
                    <EuiModalHeaderTitle>
                        <h1>{props.server?.serverName}</h1>
                    </EuiModalHeaderTitle>
                </EuiModalHeader>
                <EuiModalBody>
                    {showIPS(props.server)}
                </EuiModalBody>
                <EuiModalFooter>
                    <EuiButton onClick={addNewIp} fill>Add New Ip</EuiButton>
                    <EuiButton onClick={saveServerData} fill>Save</EuiButton>
                </EuiModalFooter>
            </EuiModal>
        </div>);
    }

    function showIPS(server:BMServer){
        return <div style={{display:"flex",flexDirection:"column"}}>
            {server.ips.map(ip=>showIP(ip))}
        </div>
    }
    function ipStatusChange(e:ChangeEvent<HTMLSelectElement>,ip:IP){
        let newStatus:IP_STATUS = IP_STATUS[e.target.value as keyof typeof  IP_STATUS];
        props.server?.ips.filter(s=>s.ip==ip.ip).forEach(s=>s.status=newStatus);
        if(props.server){
            props.onServerChange(props.server);
        }
    }
    function showIP(ip:IP){
        return <EuiFlexGroup key={ip.ip} style={{display:"flex",flexDirection:"row",padding:"3px 5px"}}>
            <EuiFlexItem style={{padding:"3px 5px",display:"none"}}>
                <label> <input name={"current_ip"} type={"radio"} checked={serverCurrentIp==ip.ip} onChange={e=>{ setServerCurrentIp(e.target.value) } } value={ip.ip}  /> &nbsp; </label>
            </EuiFlexItem>
            <EuiFlexItem style={{padding:"3px 5px"}}>
                <label> {ip.ip}</label>
            </EuiFlexItem>
            <EuiFlexItem style={{padding:"3px 5px"}}>
                <DnsLookup  domain={ip.ip}/>
            </EuiFlexItem>
            <EuiFlexItem>
                <EuiSelect
                    id={ip.status}
                    options={STATUS}
                    value={ip.status}
                    onChange={(e)=>ipStatusChange(e,ip)}
                    aria-label="Use aria labels when no actual label is in use"
                    compressed = { true }
                />
            </EuiFlexItem>
        </EuiFlexGroup>
    }


    function test(server:BMServer){
    }
    return (
        <div>
            {props.server && test(props.server)}
            {renderModel()}
        </div>
    )
}



