

export interface BMServerTraffic{
    currentTraffic: number
    splTraffic: number
    karakTraffic: number
    pastTraffic: number
    cutOff: number
    minTrafficReq: number
    traffic7DaysAgo: number
    traffic14DaysAgo: number
    trafficYesterday: number
    traffic7DaysAgoSpl : number
    traffic7DaysAgoKarak : number
    traffic14DaysAgoSpl : number
    traffic14DaysAgoKarak : number
    trafficYesterdaySpl : number
    trafficYesterdayKarak : number
    servers: Map<string,string>
}

export let emptyTraffic:BMServerTraffic = {
    currentTraffic: 0,
    splTraffic: 0,
    karakTraffic: 0,
    cutOff: 0,
    minTrafficReq: 0,
    pastTraffic: 0,
    servers: new Map<string, string>(),
    traffic14DaysAgo: 0,
    traffic7DaysAgo: 0,
    trafficYesterday:0,
    traffic7DaysAgoSpl : 0,
    traffic7DaysAgoKarak : 0,
    traffic14DaysAgoSpl : 0,
    traffic14DaysAgoKarak : 0,
    trafficYesterdaySpl : 0,
    trafficYesterdayKarak : 0
}


export function trafficURL(host:string) {
    return "http://" + host.replace(":443", "") + ":19999/api/v1/data?chart=system.net&after=-60&before=0&points=1&group=median&gtime=0&format=json&options=seconds&options=jsonwrap"
}
