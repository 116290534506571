import {BMServer, IP_STATUS} from "../model/BMServer";
import React, {FunctionComponent, useEffect, useState} from "react";
import { EuiModal, EuiModalHeader, EuiModalHeaderTitle,EuiModalBody , EuiSpacer, EuiModalFooter  , EuiButton ,EuiCodeBlock } from '@elastic/eui';
import {UpdateIPForm} from "./UpdateIPForm";
import {BMServerTraffic} from "../model/BMServerTraffic";


interface IProps {
    showModal:boolean
    servers: Map<String,BMServer>;
    setServers:React.Dispatch<React.SetStateAction<Map<String,BMServer>>>
    // setShowModal:React.Dispatch<React.SetStateAction<boolean>>
    onServerChange:(server:BMServer)=>void
    traffic : BMServerTraffic
}
export const UpdateServerModal: FunctionComponent<IProps> = (props) => {

    const [isModalVisible, setIsModalVisible] = useState(false);

    const [onSuccessScreen, setOnSuccessScreen] = useState({ status : false , content : "" });

    const closeModal = () => setIsModalVisible(false);
    const showModal = () => setIsModalVisible(true);
    const closeSuccessModal = () =>  setOnSuccessScreen({status: false,content: ""})

    let modal;
    let successModal;

    useEffect(()=>{
        console.log(props.servers)
    },[])

    if (isModalVisible) {
        modal = (
            <EuiModal onClose={closeModal}>
                <EuiModalHeader>
                    <EuiModalHeaderTitle>
                        <h1>Update servers</h1>
                    </EuiModalHeaderTitle>
                </EuiModalHeader>

                <EuiModalBody>
                    <UpdateIPForm  servers={props.servers} setServers={props.setServers}  onUpdated={(status,res,content)=> {
                        setOnSuccessScreen({ status : true , content : content });
                        closeModal()
                    } } traffic={props.traffic}/>
                </EuiModalBody>
            </EuiModal>
        );
    }
    if(onSuccessScreen.status){
        successModal = (
            <EuiModal style={{ width: "100vw" ,     maxWidth: "80vw" }}  onClose={closeSuccessModal}>
                <EuiModalHeader>
                    <EuiModalHeaderTitle>
                        <h1>Result data</h1>
                    </EuiModalHeaderTitle>
                </EuiModalHeader>

                <EuiModalBody>
                    Response from cloud functions :
                    <EuiSpacer />
                    <EuiCodeBlock language="html" isCopyable>
                        {JSON.stringify(JSON.parse(onSuccessScreen.content), null, 4)}
                    </EuiCodeBlock>
                </EuiModalBody>

                <EuiModalFooter>
                    <EuiButton onClick={closeSuccessModal} fill>
                        Close
                    </EuiButton>
                </EuiModalFooter>
            </EuiModal>

        )
    }

    return (
        <div>
            <EuiButton onClick={showModal}>Update Ip</EuiButton>
            {modal}
            {successModal}
        </div>
    );
}