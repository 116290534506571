import React from 'react';
import './App.css';

import {authentication} from "./application/FirebaseConfigs";
import {Dashboard} from "./pages/Dashboard";
import {useAuthState} from "react-firebase-hooks/auth";
import {Login} from "./pages/Login";
import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom";

function App() {
    const [user]  = useAuthState(authentication);
    return     <Router>

        <Routes>
            <Route path="/" element={  user? <Dashboard /> : <Login /> } />
        </Routes>
    </Router>
    }

export default App;
