import React, {Component, useState} from "react";
import { EuiBasicTable, EuiLink, EuiForm, EuiFlexItem ,EuiButton } from '@elastic/eui';
import {BlockReason} from "./BlockReason";
import {EuiBasicTableColumn} from "@elastic/eui/src/components/basic_table/basic_table";
import {
    authentication,
    fetchServerInfo, isDevMode,
    updateBMServer,
    updateServerApi
} from "../application/FirebaseConfigs";
import {IpList} from "./IpList";
import {BMServer, BMServerToUpdate} from "../model/BMServer";
import {BMServerTraffic} from "../model/BMServerTraffic";

interface IProps {
    servers: Map<String,BMServer>;
    setServers:React.Dispatch<React.SetStateAction<Map<String,BMServer>>>
    onUpdated : (status:boolean,res:any,content:string) => void
    traffic : BMServerTraffic
}

export class UpdateIPForm extends Component<IProps, any> {
    servers: BMServerToUpdate[] = [];
    constructor({props}: { props: any }) {
        super(props);
        this.state = {
            modalDisplay : false,
            sortDirection: 'asc',
            serverData: [],
            buttonText: 'Update'
        };
    }
    componentDidMount() {
        this.updateServer(this.props.servers)
        this.setState({serverData:this.servers})
    }

    test(row:BMServerToUpdate){
        return row.ips;
    }
    updateServer(serversObj:Map<String,BMServer>) {
        let i=0
        this.servers = []
        console.log("updateServer")
        console.log(this.props.traffic)
        for(let server of Array.from(serversObj.values())){
            this.servers.push({
                updatedBy: server.updatedBy,
                updatedOn: server.updatedOn,
                index: i, currentIp: server.currentIp, ips: server.ips, link: server.link, newIp: server.currentIp, reason: "", serverName: server.serverName, traffic: server.traffic , hasBadTraffic:false})
            i++;
        }
        // this.servers = this.servers.sort((a,b)=> b.traffic - a.traffic)
        // let serverToCompare:BMServerToUpdate|undefined
        // this.servers.forEach((n,i)=>{
        //     n.hasBadTraffic = false
        //     if(serverToCompare) n.hasBadTraffic = n.traffic<serverToCompare.traffic*.8
        //     serverToCompare = n
        //     this.servers[i] = n
        // })
        // this.servers = this.servers.reverse()
    }

    private getTraffic(currentServer:BMServer) {
        console.log(currentServer)
        let foundTraffic = this.props.traffic.servers.get(currentServer.currentIp)
        if(foundTraffic){
            return Number(foundTraffic).toFixed(0)
        }
        return 0;
    }

    render() {
        const columns:Array<EuiBasicTableColumn<BMServerToUpdate>> = [
            {
                field: 'serverName',
                name: 'Server',
                render:(v,r) => (<span>{v}- <small style={ r.hasBadTraffic? {color:"#F00"} : {color:"#0F0"} } > <b>( {this.getTraffic(r)} MBps)</b></small></span>)
            },
            {
                field: 'currentIp',
                name: 'IP',
            },
            {
                field: 'newIp',
                name: 'New IP',
                render:(v,r:BMServerToUpdate) => ( <IpList  onChange={(selectedIp) => this.servers[r.index].newIp=selectedIp }  rowData={r}/> )
            },
            {
                field: 'reason',
                name: 'Reason',
                render:(v,r:BMServerToUpdate) => ( <BlockReason onChange={(newReason) => this.servers[r.index].reason=newReason} /> )
            }
        ]

        function restructureData(serverData: BMServerToUpdate[]) {
            let sorted = serverData.sort((a,b)=> b.traffic - a.traffic)
            let serverToCompare:BMServerToUpdate|undefined;
            sorted.forEach( (n,i)=>{
                if(serverToCompare){
                    n.hasBadTraffic = n.traffic < serverToCompare.traffic
                }
                serverToCompare = n;
                sorted[i] = n;
            })
            return sorted.reverse();
        }

        return  <EuiForm >
            <EuiBasicTable
            tableCaption="Demo of EuiBasicTable"
            items={this.state.serverData}
            rowHeader="serverName"
            columns={columns}
            className={"custom-table-x"}
        />
            <div style={{margin:"20px 0 0 0 "}}>
                <EuiFlexItem grow={false}>
                    <EuiButton
                        disabled={this.state.buttonText!="Update"}
                        color="primary"
                        size="s"
                        fill
                        onClick={() => {  this.formSubmit() }}
                    >
                        { this.state.buttonText }
                    </EuiButton>
                </EuiFlexItem>
            </div>
        </EuiForm>;
    }
    formSubmit() {
        this.setState({buttonText:"Updating..."})
        this.updateData((r, content )=>{
            this.setState({buttonText:"Update"})
            this.props.onUpdated(true,r,content)
        },()=>{
            this.props.onUpdated(false,"","RC Failed to update");
        })
    }

    updateData(onSuccess: (res: any, contents: any) => void, onFail: () => void) {
        console.log(this.servers)
        updateBMServer(this.servers).then((newIpString)=>{
            console.log(newIpString)
            fetchServerInfo((updatedServers)=>{
                console.log(updatedServers)
                console.log(authentication.currentUser?.email)
                this.props.setServers(updatedServers)
                this.updateServer(updatedServers)
                this.setState({serverData: this.servers})
                if(!isDevMode) {
                    updateServerApi({
                        newIp: newIpString,
                        "user": authentication.currentUser?.email
                    }).then((r) => {
                        console.log(r)
                        // @ts-ignore
                        onSuccess(updatedServers,r.data.config)
                    }).catch((err) => {
                        console.log(err);
                        onFail()
                    });
                } else {
                    onSuccess(updatedServers,"{\"env\":\"development\" , \"txt\" : \"On dev environment we dont update servers in production\" }")
                }
            }, ()=>{})
        }).catch((e)=>{
            onFail()
        })
    }
}
