

export interface BMServer{
    serverName:string;
    traffic: number;
    ips: IP[]
    currentIp: string
    updatedBy: string
    updatedOn: number
    link:string
    hasBadTraffic:boolean
}
export enum IP_STATUS {
    ACTIVE="ACTIVE",NEW="NEW", BLOCKED="BLOCKED",DISABLED="DISABLED"
}
export interface IP{
    ip:string;
    status: IP_STATUS
}
export interface BMServerToUpdate extends BMServer{
    newIp:string
    reason:string
    index:number
}