import {authentication, db, fetchServerInfo, initFireStoreEvents} from "../application/FirebaseConfigs";
import {ServerSection} from "./ServerSection";
import {EuiFlexGrid,EuiFlexItem,EuiFlexGroup,EuiPageTemplate} from "@elastic/eui";
import { EuiProvider } from '@elastic/eui';
import {useEffect, useState} from "react";
import {MainNav} from "./MainNav";
import {BMServer} from "../model/BMServer";
import {BMServerTraffic, emptyTraffic} from "../model/BMServerTraffic";

export const Dashboard = () => {
    const [currentPage,setCurrentPage] = useState("dashboard");
    const [servers,setServers] = useState<Map<String,BMServer>>(new Map<String, BMServer>());
    const [traffic,setTraffic] = useState<BMServerTraffic>(emptyTraffic);
    const [dbError,setDbError] = useState<string>("");
    const updatePage = (newPage:string) => {
        setCurrentPage(newPage)
        return {}
    }
    let intervalTimer:NodeJS.Timer;

    function updateBMServerData() {
        fetchServerInfo((res)=>{
            setServers(res)
            initFireStoreEvents(updateBMServerData)
            if(intervalTimer) {
                // setServers(res)
            } else  {
                intervalTimer = setInterval(() => {
                    setServers(res)
                }, 100);
            }

        },()=>{ setDbError( "user id :" + authentication.currentUser?.uid ) })
    }
    useEffect(()=>{
        updateBMServerData()
    },[])
    return (
        <EuiPageTemplate>
            <EuiProvider colorMode="light">
                <MainNav setTraffic={setTraffic} traffic={traffic} servers={servers} setServers={setServers} />
                <EuiFlexGroup>
                    <EuiFlexItem>
                        { dbError == "" ? <ServerSection setServers={setServers} servers={servers} traffic={traffic}/> : <p> {dbError}</p> }
                    </EuiFlexItem>
                </EuiFlexGroup>
            </EuiProvider>
        </EuiPageTemplate>
    );
}