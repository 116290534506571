import {
    EuiCollapsibleNav,
    EuiButton,
    EuiFlexGroup,
    EuiSpacer,
    EuiText,
    EuiCode,
    EuiListGroup,
    EuiListGroupItem,
    EuiFlexItem,
    EuiBadge
} from '@elastic/eui';
import React, {FunctionComponent, useState} from 'react';
import { useNavigate } from "react-router-dom";
import {authentication} from "../application/FirebaseConfigs";
import {NetworkTrafficUpdateClass} from "./NetworkTrafficUpdateClass";
import {BMServer} from "../model/BMServer";
import {BMServerTraffic} from "../model/BMServerTraffic";

interface IProps{
    traffic : BMServerTraffic
    setTraffic:React.Dispatch<React.SetStateAction<BMServerTraffic>>
    servers: Map<String,BMServer>
    setServers:React.Dispatch<React.SetStateAction<Map<String,BMServer>>>
}
export const MainNav:FunctionComponent<IProps> = (props)=>{
    const logout = () => {
        authentication.signOut();
    }

    const [warning,setWarning] = useState<String>(  "" )

    return (
        <EuiFlexGroup style={{padding:"10px"}}  direction="column" >
            <EuiFlexItem>
                <EuiFlexGroup>
                    <EuiFlexItem>
                        <div style={{float:"left"}}>
                            <EuiButton onClick={() => logout()}> Logout </EuiButton>
                            <div style={{float:"right"}}>
                                <NetworkTrafficUpdateClass  onTrafficLow={warning => setWarning(warning)} setTraffic={props.setTraffic} traffic={props.traffic} servers={props.servers} setServers={props.setServers}/>
                            </div>
                        </div>
                    </EuiFlexItem>
                </EuiFlexGroup>
            </EuiFlexItem>
        </EuiFlexGroup>
    );
};
