import React, {FunctionComponent, useState} from "react";
import {QuerySnapshot} from "firebase/firestore";
import {EuiBasicTable, EuiLink,EuiHealth,EuiCallOut,EuiButton} from '@elastic/eui';
import {BMServer, IP_STATUS} from "../model/BMServer";
import {BMConfiguration} from "./BMConfiguration";
import {BMServerTraffic} from "../model/BMServerTraffic";

interface IProps{
    snapshot: QuerySnapshot ;
    servers: Map<String,BMServer>;
    traffic : BMServerTraffic;
    setServers:React.Dispatch<React.SetStateAction<Map<String,BMServer>>>
}

export const ServerTable: FunctionComponent<IProps> = (props) => {

    const [sortDirection, setSortDirection] = useState("asc");
    const [selectedServer,setSelectedServer] = useState<BMServer|undefined>();
    const [showConfigPage, setShowConfigPage] = useState(false);

    function bmServerClass(item: BMServer) {
        return  item.ips.filter(ip=>ip.status==IP_STATUS.ACTIVE).length > 3 ?  <EuiHealth color="success"> </EuiHealth> :  <EuiHealth color="warning"> </EuiHealth>
        // <EuiHealth color="danger">Danger</EuiHealth>
        // TODO: read lower limit of ips from somewhere else
    }

    function bmServerTraffic(item: BMServer) {
        return (item.traffic).toFixed(0)+" MB/s"
    }

    let currentTableData : BMServer[] = []

    function formatTime(timestamp: number) {
        if(isNaN(timestamp)) return " - "
        let diff = Math.round((new Date().getTime() - timestamp)/1000)
        if(diff<60)
            return "few seconds ago";
        diff = Math.round(diff/60)
        if(diff<60)
            return diff + " minutes ago";
        diff = Math.round(diff/60)
        if(diff<24)
            return diff + " hours ago";
        diff = Math.round(diff/24)
        return diff + " days ago";

    }

    const columns: any = [
        {
            field: 'serverName',
            name: 'Server',
            truncateText: true,
            render: (name: string,item: BMServer) => (<span>
                            <EuiLink onClick={()=>{
                                     setSelectedServer(item);
                                    setShowConfigPage(true)
                                }}
                              href={"#"}  > { bmServerClass(item) } {item.serverName} </EuiLink>
                        </span>
            )
        },
        {
            field: 'traffic',
            name: 'Traffic',
            render: (name: string, item: BMServer) => (
                <span  style={item.hasBadTraffic?{color:"#F00"}:{color:"#0F0"}}>{bmServerTraffic(item)}</span>
            ),
        },
        {
            field: 'currentIp',
            name: 'IP',
            render: (ip:string,item:BMServer) => (
                <a target={"_blank"} href={"https://dns.google/resolve?name="+ip}>{ip}</a>
                )
        },
        {
            field: 'updatedBy',
            name: 'Updated',
            truncateText: true,
        },
        {
            field: 'updatedOn',
            name: 'last update',
            render: (timestamp: number) => (<i> {formatTime(timestamp)} </i>)
        },
        {
            field: 'link',
            name: 'API',
            render: (link: any) => (<EuiLink href={"http://"+link+":19999/#menu_system;theme=slate" } target="_blank"> &nbsp; </EuiLink>),
            mobileOptions: {
                show: false,
            },
        }
    ]


    function onTableChange({page = {}, sort = {}}) {
        let dir = sortDirection == "desc" ? "asc" : "desc";
        setSortDirection(dir)
    }
    let badServers:BMServer[] = [];
    let lowIps:BMServer[] = [];
    function getTableData(currentServers: Map<String,BMServer>){
        badServers = [];
        lowIps = []
        let result = Array.from(currentServers.values())
        currentTableData = result.sort((a,b) =>  b.traffic - a.traffic )
        let serverToCompare:BMServer|undefined;
        currentTableData.forEach((n,i)=>{
            n.traffic = getServerTraffic(n)
            n.hasBadTraffic = false
            if((serverToCompare)&&(n.traffic<serverToCompare.traffic*0.8)){
                n.hasBadTraffic = true
                badServers.push(n)
            }
            if(n.ips.filter(ip=>ip.status==IP_STATUS.ACTIVE).length<=3){
                lowIps.push(n)
            }
            serverToCompare = n
            currentTableData[i] = n
        })

    }
    function getServerTraffic(bmserver:BMServer){
        let currentTraffic =  props.traffic.servers.get(bmserver.currentIp)
        if(currentTraffic) return Number( currentTraffic)
        return 0;
    }

    function checkForServerWithLowIp() {
        if(lowIps.length==0) return null;
        return <EuiCallOut title="Low IPs on server!" color="warning" iconType="help">
            <p> Some servers having low number of ips : { lowIps.map(n=>{return n.serverName + " ("+n.ips.filter(ip=>ip.status==IP_STATUS.ACTIVE).length+")"}).join(" ,") } </p>
        </EuiCallOut>
    }

    function checkForServerWithBadTraffic() {
        if(badServers.length==0) return null
        return <EuiCallOut title="Low traffic on server!" color="danger" iconType="alert">
            <p> Some servers having low traffic : { badServers.map(n=>{return n.serverName + " ("+n.traffic+")"}).join(" ,") } </p>
        </EuiCallOut>
    }

    return (<div>
            {console.log("rerendering server table")}
            {getTableData(props.servers)}
            {checkForServerWithLowIp()}
            {checkForServerWithBadTraffic()}
            <EuiBasicTable
                noItemsMessage={"Loading please wait"}
                tableCaption="Demo of EuiBasicTable"
                items={currentTableData.reverse()}
                rowHeader="serverName"
                columns={columns}
                className={"custom-table-1-by-3"}
                onChange={onTableChange}
            />
            <BMConfiguration onServerChange={(server=>{
                props.servers.set(server.serverName,server);
                let serverMap = Array.from(props.servers);
                let newMap = new Map();
                serverMap.map((s)=>{
                    return newMap.set(s[0],s[1])
                })
                props.setServers(newMap);
            })} server={selectedServer} showConfigPage={showConfigPage}
                             setShowConfigPage={setShowConfigPage}></BMConfiguration>
        </div>
    )


}
