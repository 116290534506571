import {EuiSelect} from "@elastic/eui";
import {FunctionComponent, useState} from "react";
import {BMServerToUpdate, IP_STATUS} from "../model/BMServer";

interface IProps{
    onChange:(selectedIp:string)=>{}
    rowData : BMServerToUpdate
}
export const IpList:FunctionComponent<IProps> = (props)=>{
    const [newIp,setNewIp] = useState(props.rowData.currentIp);
    const [listedIps,setListedIps] = useState([{ value: '', text: 'None' }]);
    let listedItems = [{ value: '', text: 'None' }]
    props.rowData.ips.forEach(n=>{  if(n.status==IP_STATUS.ACTIVE)  listedItems.push({ value: n.ip, text: n.ip }) })
    return (
        <EuiSelect
            options={listedItems}
            value={newIp}
            name={"newips"}
            onChange={(e) => {
                setNewIp(e.target.value)
                props.onChange(e.target.value)
                console.log(newIp)
            }}
            aria-label="Use aria labels when no actual label is in use"
        />
    );
}
