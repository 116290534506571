import { EuiSelect, EuiPageTemplate, EuiProvider} from "@elastic/eui";
import {ChangeEvent, FunctionComponent, useEffect, useState} from "react";
import {IP_STATUS} from "../model/BMServer";
interface IProps{
    domain:string
}
function isValidIPAddress(addr:string) {
    if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(addr)) {
        return (true)
    }
    return (false)
}
export const DnsLookup:FunctionComponent<IProps> = (myProps)=>{
    const [domain,setDomain] = useState("-");
    useEffect(()=>{
        if(isValidIPAddress(myProps.domain)){
            return
        }
        fetch("https://dns.google/resolve?name="+myProps.domain)
            .then(response=>response.json())
            .then(data=>{
                let foundDns = ""
                data["Answer"].forEach((n:any)=>{
                    foundDns = n["data"];
                })
                setDomain(foundDns);
            })

    },[])
    return (
        <span>{domain}</span>
    );
}
