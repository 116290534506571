import React from "react";
import {GoogleAuthProvider, signInWithPopup} from "firebase/auth";
import {authentication, provider} from "../application/FirebaseConfigs";


export const Login = () => {
  const signIn = () => {
    signInWithPopup(authentication, provider)
        .then((result) => {
          // This gives you a Google Access Token. You can use it to access the Google API.
          const credential = GoogleAuthProvider.credentialFromResult(result);
          const token = credential?.accessToken;
          // The signed-in user info.
          //currentUser.mail = token;

          //console.log(currentUser)
          console.log(token)
          // ...
        }).catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          const email = error.email;
          // The AuthCredential type that was used.
          const credential = GoogleAuthProvider.credentialFromError(error);
          // ...
    });
  }
  return <div className="App">
    <header className="App-header">
      <button onClick={signIn}>Log in</button>
    </header>
  </div>
}