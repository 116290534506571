import { EuiSelect, EuiPageTemplate, EuiProvider} from "@elastic/eui";
import {ChangeEvent, FunctionComponent, useState} from "react";
import {IP_STATUS} from "../model/BMServer";
interface IProps{
     onChange:(newReason:string)=>{}
 }
export const BlockReason:FunctionComponent<IProps> = (props)=>{
    const options = [
        { value: '', text: 'None' },
        { value: IP_STATUS.BLOCKED.toString() , text: IP_STATUS.BLOCKED.toString() }
    ];
    const [reason,setReason] = useState("");
    return (
            <EuiSelect
                options={options}
                value={reason}
                name={"reason[]"}
                onChange={(e) => {
                    setReason(e.target.value)
                    props.onChange(e.target.value)
                }}
                aria-label="Use aria labels when no actual label is in use"
            />
        );
}
